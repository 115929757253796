import React from 'react';
import {observer} from 'mobx-react';
import './AvailabilityBar.scss';
import GameState from 'src/components/SecurityCardsKRITIS/logic/GameState';
import avIcon from 'src/assets/SecurityCardsKRITIS/AG_Avail.svg';
import AvailabilityEffect from '../logic/effects/AvailabilityEffect';

interface StatsViewProps {
  gameState: GameState
}

/**
 * Class for the AvailabilityBar
 */
@observer
class AvailabilityBar extends React.Component
<StatsViewProps> {
  /** default constructor */
  constructor(props: StatsViewProps) {
    super(props);
  }

  /** Generate an Availability effect entry **/
  private generateAvailabilityEffectInfo(effect: AvailabilityEffect,
      key: number) {
    return (
      <li className={`${effect.availability >= 0?'AvPos':'AvNeg'}`}
        key={key}>{effect.label}</li>);
  }

  /**
   * Map a value from one range to another range
   */
  public map(value: number, fromMin: number) {
    return ((value - fromMin) * 100) / (100 - fromMin);
  }

  /** Render the StatView and its elements **/
  render() {
    const min = this.props.gameState.getMinAvailability();
    const availabilityMapped = this.map(this.props.gameState.availability, min);
    const textPosition = availabilityMapped / 2;
    const markerPosition = this.props.gameState.availabilityMarker;
    const markerPositionMapped = this.map(markerPosition, min);

    // Dynamische CSS-Klasse basierend auf dem Wert
    const progressColorClass =
    availabilityMapped < (markerPositionMapped +
      (100 - markerPositionMapped) / 3) ?
      'low' :
      availabilityMapped < (markerPositionMapped +
        (100 - markerPositionMapped) / 3 * 2) ?
      'medium' :
      'high';

    const hasAvailabilityEffects =
      this.props.gameState.activeEffects.availabilityEffects.length > 0;

    return (
      <div className='availabilityBar'>
        <div className='availabilityBarFlex'>
          <img className="availabilityBarIcon" src={avIcon}></img>
          <span className='progress-border-value'>{min} %</span>
          <div className='progressContainer'>
            <div className='marker' id='marker'
              title={markerPosition.toString() +
            ' %'} style={{left: `${markerPositionMapped}%`}}></div>
            <progress
              className={`availabilityProgress ${progressColorClass}`}
              id="progressBar"
              value={availabilityMapped}
              max="100"
            ></progress>
            <span className={`progress-value ${progressColorClass}`}
              id='progressValue'
              style={{left: `${textPosition.toFixed(1)}%`}}>
              {this.props.gameState.availability.toFixed(1)} %
            </span>
          </div>
          <span className='progress-border-value'>100 %</span>
        </div>
        {hasAvailabilityEffects && (
          <div className={`AvailabilityDetails`}>
            <ul>
              {this.props.gameState.activeEffects.availabilityEffects.map(
                  (effect, index) => {
                    return this.generateAvailabilityEffectInfo(effect, index);
                  },
              )}
            </ul>
          </div>
        )}
      </div>
    );
  }
}

export default AvailabilityBar;
