import React from 'react';
import './EffectViewContainer.scss';
import EffectView from './EffectView';
import ShieldIcon from 'src/assets/SecurityCardsKRITIS/Shield.svg';
import {Tag} from 'src/api/SecurityCardsKRITIS/SecurityEventsApi';

interface EffectViewContainerProps{
    effects: Record<Tag, number>
}

/** container class to display all active effects in effectviews */
export default class EffectViewContainer extends
  React.Component<EffectViewContainerProps> {
  /** render function */
  render() {
    // Combine effects with the same name before rendering
    return (
      <details open className="EffectViewContainer">
        <summary className='EffectViewHeading'>
          <img className="EffectViewHeadingImage"
            src={ShieldIcon}></img>
          <h3>Resistenzen:</h3></summary>
        {Object.entries(this.props.effects as Record<Tag, number>)
            .filter(([tag, value]) => tag !== 'Test' &&
              value !== undefined && !isNaN(value))
            .map(([tag, value]) => (
              <EffectView key={tag} tag={tag as Tag} value={value} />
            ))}
      </details>
    );
  }
}
