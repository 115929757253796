import React from 'react';
import './CardView.scss';
import Skill from '../logic/cards/Skill';
import HintWidget from 'src/utils/HintWidget/HintWidget';
import {AvailabilityTagIcons, TagIcons}
  from 'src/api/SecurityCardsKRITIS/SecurityEventsApi';
import ResisIcon from 'src/assets/SecurityCardsKRITIS/Shield_prime.svg';
import RedunIcon from 'src/assets/SecurityCardsKRITIS/AG_Avail_prime.svg';
import ReactiveIcon from 'src/assets/SecurityCardsKRITIS/Reaction.svg';
import MoneyIcon from 'src/assets/SecurityCardsKRITIS/MoneyIcon.svg';
import PlaceholderIcon from 'src/assets/SecurityCardsKRITIS/AW_Cloud_white.svg';


/** CardViewProps interface**/
export interface CardViewProps {
  skill: Skill,
  onClickFunction: ((skill: Skill) => void)| undefined,
  usable: boolean,
  isMinified: boolean,
}

/**
 * The Security Card View Component
 */
class CardView extends React.Component<CardViewProps> {
  /** Constructor using CardProps **/
  constructor(props: CardViewProps) {
    super(props);
  }

  /** wrepper for onClick calling specified callback with skill argument **/
  select = () => {
    if (this.props.onClickFunction) {
      this.props.onClickFunction(this.props.skill);
    }
  };


  /** Render the card view **/
  render() {
    let card = 'income';
    let cardIcon = MoneyIcon;
    if (this.props.skill.cardType == 'preventive') {
      card = 'preventive';
    } else if (this.props.skill.cardType == 'reactive') {
      card = 'reactive';
      cardIcon = ReactiveIcon;
    } else if (this.props.skill.cardType == 'redundancy') {
      card = 'redundancy';
      cardIcon = RedunIcon;
    } else if (this.props.skill.cardType == 'resistancy') {
      card = 'resistancy';
      cardIcon = ResisIcon;
    }

    return (
      <div onClick={this.select}
        className={
          `CardView${this.props.isMinified?'-Minified':'-Full'}
          ${this.props.usable? '': 'CardDisabled'} CardType${card}`}
        /* style={{backgroundImage: `url(${card})`}}*/ >
        <img className="CardIcon" src={cardIcon}></img>
        <h3 className="CardName">
          {this.props.skill.label}
        </h3>
        <div className='CardTags'>
          {
            card=='resistancy'?this.props.skill.tags.map((tag, index)=>{
              return (<img key={index} src={TagIcons[tag]}></img>);
            }):this.props.skill.avTags.map((tag, index)=>{
              return (<img key={index} src={AvailabilityTagIcons[tag]}></img>);
            })
          }
        </div>
        <div className="CardDescription">
          <p>
            {this.props.skill.description}
          </p>
        </div>
        {this.props.skill.hintText?
          <HintWidget hintText={this.props.skill.hintText}
            inCard={true}/>:undefined}
        <div className="CardEffect">
          {this.props.skill.effectText}
        </div>
      </div>
    );
  }
}

export default CardView;
