import React from 'react';
import './SecurityCards.scss';
import Skill from './logic/cards/Skill';
import StatusBar from './StatusBar/StatusBar';
import TopBar from './TopBar/TopBar';
import CardDetailScreen from './CardDetailScreen/CardDetailScreen';
import EventView from './EventView/EventView';
import CardFinishOverview from './CardFinishOverview/CardFinishOverview';
import {observer} from 'mobx-react';
import 'react-toastify/dist/ReactToastify.css';
import GameState, {Page} from './logic/GameState';
import ShopView from './ShopView/ShopView';
import Overlay from 'src/utils/Overlay/Overlay';
import CardSlider from './CardSlider/CardSlider';
import SecurityCardsExplainPage from
  './SecurityCardsExplainPage/SecurityCardsExplainPage';
import FeedbackWidget from 'src/utils/FeedbackWidget/FeedbackWidget';
import ProbabilityMeter from './ProbabilityMeter/ProbabilityMeter';
import HistoryView from './HistoryView/HistoryView';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SecurityCardsLevelSelection from
  './SecurityCardsLevelSelection/SecurityCardsLevelSelection';

/**
 * The Security Cards Scelleton
 */
@observer
class SecurityCards extends React.Component {
  gameState: GameState;
  cardDetailView: React.RefObject<CardDetailScreen>;
  cardBlockedView: React.RefObject<Overlay>;

  /** default constructor */
  constructor() {
    super({});
    this.gameState = new GameState();
    this.cardDetailView = React.createRef();
    this.cardBlockedView = React.createRef();
  }

  /** Component did mount **/
  componentDidMount() {
    this.gameState.data.loadLevels().then(() => {
      this.gameState.init();
    });
  }

  /** Handle the card selection **/
  handleCardSelect = (selectedSkill: Skill) => {
    this.gameState.skills.selectSkill(selectedSkill);
    if (this.gameState.activeEffects.isCardBlocked(selectedSkill)) {
      this.cardBlockedView.current?.toggle();
    } else {
      this.cardDetailView.current?.show(selectedSkill);
    }
  };

  /** Handle the card played **/
  handleCardPlayed = (selectedSkill: Skill) => {
    if (this.gameState) {
      this.gameState.applySkill(selectedSkill);
      this.gameState.skills.removeSkillCard(selectedSkill);
    }
  };

  /** handle ok Explainpage **/
  handleOKExplainpage = () => {
    this.gameState.startTutorial();
  };

  /** Generate list view of block reasons **/
  generateBlockReasons(skill: Skill | undefined): JSX.Element {
    if (skill == undefined) {
      return <ul></ul>;
    }
    const reasons = this.gameState.activeEffects.getBlockReasons(skill);
    return (
      <ul>
        {reasons.map((reason, index) =>
          <li key={index}>{reason}</li>,
        )}
      </ul>
    );
  }

  /** Render the GamePage **/
  render() {
    let eventView: JSX.Element;
    let content: JSX.Element | undefined;
    switch (this.gameState.page) {
      case Page.loading:
        content = <div className='Loading'>
          <div className= 'Loader'></div>
          <h1>Loading ...</h1>
        </div>;
        break;
      case Page.explain:
        content = <SecurityCardsExplainPage clickEvent={
          this.handleOKExplainpage} />;
        break;
      case Page.levelselection:
        content = <SecurityCardsLevelSelection
          clickEvent = {(level) => {
            this.gameState.selectLevel(level);
          }
          } levels={this.gameState.data.levels} />;
        break;
      case Page.finished:
        content = (
          <div className="FinishPage">
            <FeedbackWidget link={'https://umfragen.htw-aalen.de/uc/baksecure_feedback1_sec_cards/'}
              position={[40, 100]}
              text={'Zur Umfrage'}
            />
            <CardFinishOverview money={this.gameState.money}
              kw={this.gameState.turn}
              available={this.gameState.checkAvailable()} />
            <HistoryView history={this.gameState.history} />
          </div>
        );
        break;
      case Page.game:
        if (this.gameState.activeEvent) {
          eventView = (<EventView
            event={this.gameState.activeEvent}
            showEventDescription={this.gameState.showEventDescription}
          />);
        } else {
          eventView = (<React.Fragment/>);
        }
        /* Render SecurityCards gamemap */
        content = (
          <div className="GameBody">
            {
              // Overlays
            }
            <CardDetailScreen ref={this.cardDetailView}
              useFunction={this.handleCardPlayed}
              activeEvent={this.gameState.activeEvent}
            />
            <ShopView gameState={this.gameState} />
            {
              // Permanent visible
            }
            <div className='GameView'>
              <StatusBar gameState={this.gameState}/>
              <div className='GameContainer'>
                <div className='GameBoard'>
                  {eventView}
                  <ProbabilityMeter gamestate={this.gameState}/>
                </div>
                <div className="HandCards">
                  <div className="HandCardsOverlay"
                    style={{
                      display: this.gameState.showEventDescription ?
                        'block' : 'none'}}>
                  </div>
                  <CardSlider gameState={this.gameState}
                    handleCardSelect={this.handleCardSelect}></CardSlider>
                </div>
              </div>
            </div>
          </div>
        );
    }

    return (
      <div className="SecurityCards">
        <Overlay ref={this.cardBlockedView}>
          <div className="BlockedViewContent">
            <h2> Fähigkeit Blockiert! </h2>
            <h3> Die Fähigkeit steht aus den folgenden
                 Gründen nicht zur Verfügung:</h3>
            {this.generateBlockReasons(this.gameState.skills.selectedSkill)}
            <button className="DesignBTN confirmBTN"
              onClick={this.cardBlockedView.current?.toggle}>OK</button>
          </div>
        </Overlay>
        <TopBar gameState={this.gameState}/>
        <div className="CardGameBoard">
          {content}
        </div>
        <ToastContainer />
      </div>
    );
  }
}

export default SecurityCards;
