import React from 'react';
import './EventViewTag.scss';

import Effect from 'src/components/SecurityCardsKRITIS/logic/effects/Effect';
import {Tag, TagIconsPrimary}
  from 'src/api/SecurityCardsKRITIS/SecurityEventsApi';
import ProbabilityEffect, {isProbabilityEffect}
  from '../logic/effects/ProbabilityEffect';

interface EventViewTagProps {
    tag: Tag,
}

/** Component to render single effect, currently supports ProbabilityEffects */
export default class EventViewTag extends React.Component<EventViewTagProps> {
  /** render function */
  render() {
    // add cases for cardeffect
    const src = TagIconsPrimary[this.props.tag];
    return (
      <img className='HelpfulTagImage'
        title={`${this.props.tag} Resistance`}
        src={src}/>
    );
  }
}
