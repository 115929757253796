import {getJSON} from 'src/utils/Requests/Requests';

import UserFault from 'src/assets/SecurityCardsKRITIS/AW_User.svg';
import Physical from 'src/assets/SecurityCardsKRITIS/AW_Phys.svg';
import Data from 'src/assets/SecurityCardsKRITIS/AG_Daten.svg';
import Spam from 'src/assets/SecurityCardsKRITIS/AW_E-Mail.svg';
import Cloud from 'src/assets/SecurityCardsKRITIS/AW_Cloud.svg';
import Network from 'src/assets/SecurityCardsKRITIS/AW_Network.svg';
import Dos from 'src/assets/SecurityCardsKRITIS/AW_DoS.svg';
import Infrastructure from 'src/assets/SecurityCardsKRITIS/AW_Infra.svg';
import UserFaultWhite from 'src/assets/SecurityCardsKRITIS/AW_User_white.svg';
import PhysicalWhite from 'src/assets/SecurityCardsKRITIS/AW_Phys_white.svg';
import DataWhite from 'src/assets/SecurityCardsKRITIS/AG_Daten_white.svg';
import SpamWhite from 'src/assets/SecurityCardsKRITIS/AW_E-Mail_white.svg';
import CloudWhite from 'src/assets/SecurityCardsKRITIS/AW_Cloud_white.svg';
import NetworkWhite from 'src/assets/SecurityCardsKRITIS/AW_Network_white.svg';
import DosWhite from 'src/assets/SecurityCardsKRITIS/AW_DoS_white.svg';
import InfrastructureWhite
  from 'src/assets/SecurityCardsKRITIS/AW_Infra_white.svg';
import AvailNetwork
  from 'src/assets/SecurityCardsKRITIS/Availability_network_white.svg';
import AvailPower
  from 'src/assets/SecurityCardsKRITIS/Availability_energy_white.svg';
import AvailSP
  from 'src/assets/SecurityCardsKRITIS/Availability_prov_white.svg';
import AvailServer
  from 'src/assets/SecurityCardsKRITIS/Availability_server_white.svg';
import AvailCloud
  from 'src/assets/SecurityCardsKRITIS/Availability_cloud_white.svg';
import AvailStaff
  from 'src/assets/SecurityCardsKRITIS/Availability_worker_white.svg';
/** Events and SkillCards are connected via this Tags **/
export type Tag = 'Data' | 'UserFault' | 'Spam' |'Infrastructure'|
 'DOS' | 'Test'|'Network'|'Cloud'|'Physical';

export const TagIcons: Record<Tag, string|undefined> = {
  'Data': DataWhite,
  'Cloud': CloudWhite,
  'Network': NetworkWhite,
  'Physical': PhysicalWhite,
  'UserFault': UserFaultWhite,
  'Spam': SpamWhite,
  'DOS': DosWhite,
  'Infrastructure': InfrastructureWhite,
  'Test': undefined,
};

export const TagIconsPrimary: Record<Tag, string|undefined> = {
  'Data': Data,
  'Cloud': Cloud,
  'Network': Network,
  'Physical': Physical,
  'UserFault': UserFault,
  'Spam': Spam,
  'DOS': Dos,
  'Infrastructure': Infrastructure,
  'Test': undefined,
};

export type AvailabilityTag = 'Power' | 'Internet' | 'IT-Serviceprovider' |
 'Server' | 'Cloud' | 'Staff';

export const AvailabilityTagIcons: Record<AvailabilityTag,
string|undefined> = {
  'Internet': AvailNetwork,
  'Power': AvailPower,
  'IT-Serviceprovider': AvailSP,
  'Server': AvailServer,
  'Cloud': AvailCloud,
  'Staff': AvailStaff,
};

/** IEvent interface representing any kind of event data**/
export interface IEvent {
  label:string,
  name: string,
  description: string,
  occurText: string,
  notOccurText: string,
  probability: number,
  cost: number,
  tags: Tag[],
  effectNames?: string[] | undefined,
  severity?: number | undefined,
  hintText?:string,
  requiredActions?: string[] | undefined,
  category?:string,
  failureDays?:number|undefined,
  availabilityTargets?:AvailabilityTag[]|undefined,
}

/** API prefix **/
const PATH_PREFIX = '/SecurityCards/api/events/';

/** fetch event jsons from server and return array of event objects */
export async function getEvents(prefix: string,
    eventNames: string[]): Promise<IEvent[]> {
  const events: IEvent[] = [];
  for (let i = 0; i < eventNames.length; i ++) {
    events.push(await getJSON(PATH_PREFIX +prefix+ eventNames[i] + '.json'));
  }
  return events;
}
