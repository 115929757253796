import React from 'react';
import './EventView.scss';
import {observer} from 'mobx-react';
import HintWidget from 'src/utils/HintWidget/HintWidget';
import Event from 'src/components/SecurityCardsKRITIS/logic/cards/Event';
import EventViewTag from './EventViewTag';
import ShieldIcon from 'src/assets/SecurityCardsKRITIS/Shield.svg';
import damaging from 'src/assets/SecurityCardsKRITIS/eventcard_damaging.svg';
import harmless from 'src/assets/SecurityCardsKRITIS/eventcard_harmless.svg';
import incident from 'src/assets/SecurityCardsKRITIS/eventcard_incidents.svg';

interface EventViewProps {
  event: Event,
  showEventDescription: boolean,
}


/** Class to view event Text **/
@observer
class EventView extends React.Component<EventViewProps> {
  /** Default constructor with props **/
  constructor(props: EventViewProps) {
    super(props);
  }

  /** Render functionality **/
  render() {
    let occurDescription: string;
    let occurClass: 'good' | 'bad' | 'empty';
    occurClass='empty';
    const harmlessEvent = this.props.event.category == 'harmless';
    let cardImg;
    switch (this.props.event.category) {
      case 'harmless':
        cardImg = harmless;
        break;
      case 'damaging':
        cardImg = damaging;
        break;
      case 'incident':
        cardImg = incident;
        break;
    }

    if (this.props.showEventDescription) {
      occurDescription = '';
    } else if (this.props.event.occurs) {
      occurDescription = this.props.event.occurText;
      occurClass = harmlessEvent? 'good': 'bad';
    } else {
      occurDescription = this.props.event.notOccurText;
      occurClass = harmlessEvent? 'bad': 'good';
    }

    return (
      <div className="EventView">
        <div className='ImageFrame'>
          <img className='EventIcon' src={cardImg}></img>
        </div>
        <div className='TextFrame'>
          <h3>{this.props.event.label}</h3>
          {(this.props.event.hintText) ?
            (<HintWidget
              hintText={this.props.event.hintText} />) :
            undefined}
          <p className="Description">
            {this.props.event.description}
          </p>
          {this.props.event.tags.length > 0?
          <h4 className='HelpfulTags'>Hilfreiche Resistenzen {' '}
            <img className="HelpfulTagsHeadingImage"
              src={ShieldIcon}></img>:
            {this.props.event.tags.map((tag)=>(<EventViewTag
              key={tag} tag={tag} />))}
          </h4> : '' }
          <p className={`occurText ${occurClass}`}>
            {occurDescription}
          </p>
        </div>
      </div>
    );
  }
}

export default EventView;
