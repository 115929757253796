import React from 'react';
import './RedundancyBar.scss';
import {AVAILABILITY_CAP} from '../logic/effects/Effects';
import {AvailabilityTag} from 'src/api/SecurityCardsKRITIS/SecurityEventsApi';
import GameState from '../logic/GameState';
import AvailabilityEffect from '../logic/effects/AvailabilityEffect';

interface RedundancyBarProps {
    icon: string,
    tooltip: string,
    gameState: GameState,
    tag: AvailabilityTag
}

/** Component to render single effect, currently supports ProbabilityEffects */
export default class RedundancyBar extends React.Component<RedundancyBarProps> {
  /** Generate an Availability effect entry **/
  private generateAvailabilityEffectInfo(effect: AvailabilityEffect,
      key: number) {
    return (
      <li className={`${effect.availability >= 0?'AvPos':'AvNeg'}`}
        key={key}>{effect.label}</li>);
  }

  /** render function */
  render() {
    const redundancy =
    this.props.gameState.activeEffects.
        availabilityLevels[this.props.tag];
    const filteredEffects =
    this.props.gameState.activeEffects.availabilityEffects
        .filter((effect) => effect.tag === this.props.tag);

    return (
      <div className='RedundancyWrapper'>
        <div className='RedundancyWrapperFlex'>
          <img className="RedundancyIcon" title={this.props.tooltip}
            src={this.props.icon} />
          <div className='RedundancyBar'>
            {Array.from({length: (AVAILABILITY_CAP + 1)}, (_, i) => (
              <div className={`RedundancyItem` +
            `${redundancy > i ? ' highlight' : ''}`} key={i} />
            ))}
          </div>
        </div>
        {filteredEffects.length > 0 &&
        <div className={`AvailabilityDetails`}>
          <ul>
            {
              this.props.gameState.activeEffects.availabilityEffects.filter(
                  (effect)=>effect.tag == this.props.tag).map(
                  (effect, index) => {
                    return this.generateAvailabilityEffectInfo(effect, index);
                  },
              )
            }
          </ul>
        </div>
        }
      </div>
    );
  }
}
