import React from 'react';
import {observer} from 'mobx-react';
import './AvailabilityBattery.scss';
import GameState from
  'src/components/SecurityCardsKRITIS/logic/GameState';
import RedundancyBar from './RedundancyBar';
import avCloud from
  'src/assets/SecurityCardsKRITIS/Availability_cloud.svg';
import avEnergy from
  'src/assets/SecurityCardsKRITIS/Availability_energy.svg';
import avNetwork from
  'src/assets/SecurityCardsKRITIS/Availability_network.svg';
import avServer from
  'src/assets/SecurityCardsKRITIS/Availability_server.svg';
import avWorker from
  'src/assets/SecurityCardsKRITIS/Availability_worker.svg';
import avProv from 'src/assets/SecurityCardsKRITIS/Availability_prov.svg';
import avIcon from 'src/assets/SecurityCardsKRITIS/AG_Avail.svg';

interface StatsViewProps {
    gameState: GameState
}

class AvailabilityIcons extends React.Component
<StatsViewProps> {
  /** default constructor */
  constructor(props: StatsViewProps) {
    super(props);
  }

  /** Render the StatView and its elements **/
  render() {
    return (
      <details open className="AvailabilityIconArea">
        <summary className='AvailabilityHeader'>
          <img className="AvailabilityHeaderIcon"
            src={avIcon}></img>
          <h3>Redundanz:</h3>
        </summary>
        <div className='AvailabilityDetailsContent'>
          <RedundancyBar icon={avNetwork} gameState={this.props.gameState}
            tag='Internet' tooltip='Netzwerk'/>
          <RedundancyBar icon={avWorker} gameState={this.props.gameState}
            tag='Staff' tooltip='Mitarbeiter'/>
          <RedundancyBar icon={avEnergy} gameState={this.props.gameState}
            tag='Power' tooltip='Stromversorgung'/>
          <RedundancyBar icon={avCloud} gameState={this.props.gameState}
            tag='Cloud' tooltip='Cloud'/>
          <RedundancyBar icon={avServer} gameState={this.props.gameState}
            tag='Server' tooltip='Server'/>
          <RedundancyBar icon={avProv} gameState={this.props.gameState}
            tag='IT-Serviceprovider' tooltip='Dienstleister'/>
        </div>
      </details>
    );
  }
}

export default AvailabilityIcons;
