import React from 'react';
import './ShopView.scss';
import {observer} from 'mobx-react';
import GameState from '../logic/GameState';
import CardView from '../CardView/CardView';
import Overlay from 'src/utils/Overlay/Overlay';
import {formatMoney} from 'src/utils/Formatters/Formatters';

interface ShopViewProps {
  gameState: GameState,
}

/** Display a shop view, where you can buy cards **/
@observer
class ShopView extends React.Component<ShopViewProps> {
  /** Constructor with props **/
  constructor(props: ShopViewProps) {
    super(props);
  }
  /** handle tutorial step on card selection */
  cardClickOnTutorial = () => {
    if (this.props.gameState.tutorialStep == 5) {
      this.props.gameState.tutorialStep += 1;
    }
  };
  /** handle tutorial step on buying a card */
  handleCardBuy = () => {
    this.props.gameState.shop.buy(this.props.gameState);
    if (this.props.gameState.tutorialStep == 6) {
      this.props.gameState.tutorialStep += 1;
    }
  };
  /** Redner the shop as overlay**/
  render() {
    let confirmOverlay: JSX.Element = <></>;
    if (!this.props.gameState.shop.shopVisible) {
      return <React.Fragment/>;
    }
    if (this.props.gameState.shop.selectedSkill != undefined) {
      confirmOverlay = (<Overlay visible={true}>
        <div className="ShopContent ShopConfirm">
          <h3>Diese Karte für {
            formatMoney(this.props.gameState.shop.selectedSkill.price)
          }kaufen?
          </h3>
          <CardView isMinified={false}
            skill={this.props.gameState.shop.selectedSkill} usable={true}
            onClickFunction={this.props.gameState.shop.handleCardSelect}/>
          <button className={
              this.props.gameState.tutorialStep == 6?
              'DesignBTN confirmBTN BuyBTNGlow':
              'DesignBTN confirmBTN'}
          onClick={()=> this.handleCardBuy()}
          data-arrow={
            this.props.gameState.tutorialStep == 6 ?
            '→': ''}>
            Kaufen{this.props.gameState.shop.selectedSkill.isAutoPlay ?
            ' und Aktivieren' : ''}
          </button>
          <button className="DesignBTN cancelBTN"
            onClick={
              ()=>this.props.gameState.shop.handleCardSelect(
                  this.props.gameState.shop.selectedSkill)}>
            Abbrechen</button>
        </div>
      </Overlay>);
    }
    return (
      <Overlay visible={true}>
        <div className="ShopContent ShopBody">
          {this.props.gameState.shop.insufficientFunds ?
          <h1>Sie haben aktuell nicht genügend
             Geld um diese Karte zu kaufen</h1> : <></>}
          <h2>Karten Shop</h2>
          <span>Geld: {formatMoney(this.props.gameState.money)}</span>
          <ul className="ShopCards">
            {this.props.gameState.shop.cardsInShop.map((card, index) =>
              <li
                onClick={() => this.cardClickOnTutorial()}
                className={
                this.props.gameState.tutorialStep == 5?
                'CardGlow':''}
                key={index}>
                <h4 className='PriceTag'>
                  {formatMoney(card.price)}
                </h4>
                <CardView isMinified={true} usable={true} skill={card}
                  onClickFunction={
                    this.props.gameState.shop.handleCardSelect} />
              </li>,
            )}
          </ul>
          <button className="DesignBTN neutralBTN"
            onClick={() => this.props.gameState.shop.toggleShopVisibility()}>
            Shop Schließen</button>
          {confirmOverlay}
        </div>
      </Overlay>
    );
  }
}

export default ShopView;
