import Event from './cards/Event';
import {makeObservable, observable, action, computed} from 'mobx';
import GameData from './GameData';
import Effects from './effects/Effects';
import ShopState from './ShopState';
import SkillContainer from './SkillContainer';
import History from './History';
import Skill from './cards/Skill';
import {ILevel} from 'src/api/SecurityCardsKRITIS/LevelsApi';
import ProbabilityEffect from './effects/ProbabilityEffect';
import {AvailabilityTag} from 'src/api/SecurityCardsKRITIS/SecurityEventsApi';

export enum Page {
  loading,
  explain,
  game,
  finished,
  levelselection,
}

export const MAX_ROUNDS = 12;
/** gamestate class with state variables and
 * functionality for SecurityCards component */
export default class GameState {
  activeEvent: Event | undefined;
  money = 0;
  availabilityMarker = 95;
  availabilities = [0, 20, 40, 60, 80, 100];
  eventsInLevel = 0;
  turn = 0;
  tutorialStep = 0;
  data: GameData = new GameData;
  history = new History();
  page: Page = Page.explain;
  shop: ShopState = new ShopState();
  activeEffects: Effects = new Effects();
  skills: SkillContainer = new SkillContainer(this.data, this.activeEffects);
  showEventDescription = true;
  probMeterIsMoving = false;
  // Initial data
  failedDays = 0;
  upcomingEvents: string[] = [];
  initialEffects = ['incomeBasicIncome'];
  initialSkills = ['CreateBackups'];
  // Level Events
  // Variable Kalenderwoche
  /** Constructor setting up MobX **/
  constructor() {
    makeObservable(this, {
      nextTurnEnabled: action,
      activeEvent: observable,
      money: observable,
      availability: computed,
      availabilityMarker: observable,
      availabilities: observable,
      turn: observable,
      page: observable,
      showEventDescription: observable,
      probMeterIsMoving: observable,
      tutorialStep: observable,
      decreaseMoney: action,
      increaseMoney: action,
      applyMoneyEffects: action,
      checkEvent: action,
      addUpcomingEvents: action,
      nextEvent: action,
      showFinishedPage: action,
      startGame: action,
      toggleEventDescription: action,
      toggleProbMeterAnimation: action,
      handleEventActivation: action,
      init: action,
      showLevelSelectionPage: action,
      showLoadingPage: action,
      shopAvailable: computed,
      getMaxRounds: action,
      getAvailabilityVisibility: action,
      getMinAvailability: action,
      getStartAvailability: action,
      getMarkerAvailability: action,
      checkAvailable: action,
      endTutorial: action,
      decreaseAvailabilityMarker: action,
      increaseAvailabilityMarker: action,
      applyFailedDays: action,
      failedDays: observable,
      setTutorialStep: action,
    });
  }

  /** increase the number of failed days for availability score
  */
  applyFailedDays() {
    const tags = ['Power', 'Internet', 'IT-Serviceprovider',
      'Server', 'Cloud', 'Staff'];
    tags.forEach((tag) => {
      if (this.activeEffects.availabilityLevels[tag as AvailabilityTag] < 1) {
        this.failedDays += this.activeEffects.
            availabilityDays[tag as AvailabilityTag];
      }
    });
  }

  setTutorialStep(value: number) {
    this.tutorialStep = value;
  }

  /** set maximum available playing rounds */
  getMaxRounds() : number {
    if (this.data.loadedLevel == undefined) {
      return 0;
    }
    return this.data.loadedLevel.rounds;
  }

  /** set minimum Availability for mapping */
  getMinAvailability() : number {
    if (this.data.loadedLevel == undefined) {
      return 0;
    }
    return this.data.loadedLevel.minAvailability;
  }

  /** set start Availability */
  getStartAvailability() : number {
    if (this.data.loadedLevel == undefined) {
      return 0;
    }
    return this.data.loadedLevel.startAvailability;
  }

  /** set Availability of Marker */
  getMarkerAvailability() : number {
    if (this.data.loadedLevel == undefined) {
      return 0;
    }
    return this.data.loadedLevel.markerAvailability;
  }

  /** Set Visibility of Availability-Bar */
  getAvailabilityVisibility() : boolean {
    if (this.data.loadedLevel == undefined) {
      return false;
    }
    return this.data.loadedLevel.showAvailability;
  }

  /** Check if Availability is above limit */
  checkAvailable() : boolean {
    if (this.getAvailabilityVisibility()) {
      return this.availability >= this.getMarkerAvailability();
    } else {
      return true;
    }
  }

  /** decrease money by given value */
  decreaseMoney(value: number) {
    this.money -= value;
  }

  /** increase money by given value */
  increaseMoney(value: number) {
    this.money += value;
  }

  /** decrease availability marker by given value */
  decreaseAvailabilityMarker(value: number) {
    this.availabilityMarker -= value;
  }

  /** increase availability marker by given value */
  increaseAvailabilityMarker(value: number) {
    this.availabilityMarker += value;
  }

  /** prepare LevelSelection of Events */
  selectLevel(level: ILevel) {
    this.showLoadingPage();
    this.data.loadLevel(level).then(
        () => {
          this.getMaxRounds();

          // Set Availability and AvailabilityMarker
          this.availabilityMarker = this.getMarkerAvailability();

          if (level.name == 'tutorialLevel') {
            this.setTutorialStep(0);
            this.startTutorial();
          } else {
            this.startGame();
          }
        },
    );
  }

  /**
  * loads the upcomming events from game data
  */
  addUpcomingEvents() {
    if (this.tutorialStep > 0) {
      if (this.data.loadedLevel !== undefined) {
        const incidents = this.data.loadedLevel.incidentEventNames;
        const damaging = this.data.loadedLevel.damagingEventNames;
        const harmless = this.data.loadedLevel.harmlessEventNames;
        // first harmless, then damaging, then incidents in tutorial
        this.upcomingEvents = incidents.concat(damaging, harmless);
      }
    } else {
      this.upcomingEvents = this.data.getUpcomingEvents();
    }
  }

  /** shop is disabled when incident appears */
  get shopAvailable(): boolean {
    return !(this.activeEvent?.category == 'incident' &&
    this.showEventDescription);
  }

  /** Calculates and returns the availability score
   *  in percent
   *  based on total days and failed days
   */
  get availability(): number {
    const availability = (((this.getMaxRounds() * 5) - this.failedDays) /
    (this.getMaxRounds() * 5)) * 100;
    return availability >= 100 ? 99.9 : (availability < 0 ? 0 : availability);
  }

  /** Check if the event occurs,
   * this depends on the awarness levels and a random
   * number. the evaluation result is saved
   **/
  checkEvent() {
    if (this.activeEvent == undefined) {
      return false;
    }
    if (this.activeEvent.occurs != undefined) {
      return this.activeEvent.occurs;
    }
    const randomValue = Math.floor((Math.random() * 100));
    /** Sum up positive and negative effects separately.
     * Random number + negative effects is capped at 100.
     * It is the minimum of either the random number
     * or 100 - negative effects.
     * The Event occurs if the random value + negative
     * effects is greater than the inverse base protection
     * + positive effects.
     **/
    let positiveEffects = 0;
    let negativeEffects = 0;
    for (const effect of this.activeEffects.effects) {
      const {tag, probability} = effect as ProbabilityEffect;
      if (this.activeEvent.tags.includes(tag) && probability < 0) {
        negativeEffects += Math.abs(probability);
      } else if (this.activeEvent.tags.includes(tag)) {
        positiveEffects += probability;
      }
    }
    const attackValue = Math.min(randomValue, 100 - negativeEffects);
    this.activeEvent.occurs = (attackValue + negativeEffects >
      100 - this.activeEvent.probability + positiveEffects);
    this.activeEvent.roll = attackValue;
    return this.activeEvent.occurs;
  }

  /**
   * applys the specified skills effects to gamestate
   * subtracts cost
   * adds skill to used skills
   * @param skill
   * @returns undefined
   */
  applySkill(skill: Skill) {
    if (this.activeEvent == undefined) {
      return;
    } else if (skill.isProbability == false &&
       skill.isAvailability == false &&
       skill.checkFor(this.activeEvent)) {
      switch (skill.operation) {
        case 'ADD':
          this.activeEvent.cost += skill.effectValue;
          break;
        case 'SUB':
          this.activeEvent.cost -= skill.effectValue;
          if (this.activeEvent.cost < 0) {
            this.activeEvent.cost = 0;
          }
          break;
        case 'SET':
          this.activeEvent.cost = skill.effectValue;
          break;
      }
    }

    skill.effects?.forEach((effect)=>this.activeEffects.addEffect(effect));
    if (skill.playPrice) {
      this.decreaseMoney(skill.playPrice);
    }
    this.skills.addToUsedSkills(skill);
  }

  /** calls increase- or decreaseMoney for all incomeValues from effects*/
  applyMoneyEffects() {
    let sum = 0;
    this.activeEffects.incomeValues.forEach((amount) => {
      sum += amount;
    });

    if (sum >= 0) {
      this.increaseMoney(sum);
    } else {
      this.decreaseMoney(-sum);
    }
  }

  /** set next event to active event,
   * take next upcoming event and set it to active.
  */
  nextEvent(): Event | undefined {
    // * Deploy new Event
    if (this.upcomingEvents) {
      const eventName = this.upcomingEvents.pop();
      if (eventName) {
        this.turn++;
        const event = this.data.getEvent(eventName);
        this.activeEvent = event;
        return event;
      }
    }
    this.activeEvent = undefined;
    return undefined;
  }

  /** show finished page **/
  showFinishedPage() {
    this.page = Page.finished;
  }

  /** show loading page **/
  showLoadingPage() {
    this.page = Page.loading;
  }
  /**
   * start next turn in game
   * apply incomeValues to money
   * select next event
   * clear usedSkills
   * able
   */
  nextTurn() {
    if (this.tutorialStep < 0 && this.turn > 0) {
      this.history.addTurn(this);
    }
    this.skills.clearUsedSkills();
    this.applyFailedDays();
    this.activeEffects.tick();
    if (this.nextEvent()) {
      this.applyMoneyEffects();
      if ((this.money<0)) {
        this.history.createEventMap(this);
        setTimeout(() => this.showFinishedPage(), 130);
      }
      if (!this.checkAvailable()) {
        setTimeout(() => this.showFinishedPage(), 130);
      }
    } else {
      if (this.tutorialStep > 0) {
        this.endTutorial();
      } else {
        this.history.createEventMap(this);
        setTimeout(() => this.showFinishedPage(), 1300);
      }
    }
  }

  /** loads initial effects from data and adds them to effects */
  private loadInitialEffects() {
    this.initialEffects.map((effect) => this.data.getEffect(effect)).
        forEach((effect) => this.activeEffects.addEffect(effect));
  }
  /** Show/Hide event description. **/
  toggleEventDescription() {
    this.showEventDescription = !this.showEventDescription;
  }

  /** toggle probabilitymeter flag */
  toggleProbMeterAnimation() {
    this.probMeterIsMoving = !this.probMeterIsMoving;
  }

  /** gets called after provbabilitymeter stops
   * applies event to state and shows eventdescription
   */
  handleEventActivation() {
    if (this.activeEvent && this.activeEvent.occurs) {
      this.activeEvent.applyTo(this);
    }
    this.toggleEventDescription();
  }

  /** checks if an events required actions are satisfied
   *  @returns true if none exist or required actions are satisfied
   *  @returns false if required actions are not satisfied
   */
  nextTurnEnabled() {
    if ( this.activeEvent?.occurs &&
    this.activeEvent?.requiredActions != undefined) {
      // check if any of the required skills got played
      for (const entry of this.activeEvent.requiredActions) {
        if (this.skills.usedSkills.includes(entry)) {
          return true;
        }
      }
      return false;
    } else {
      return true;
    }
  }

  /** Start with default values **/
  startGame() {
    this.page = Page.game;
    this.addUpcomingEvents();
    this.skills.addSkillCards(this.initialSkills);
    this.loadInitialEffects();
    this.shop.refillShop(this.data);
    this.nextTurn();
  }

  /** Start tutorial **/
  startTutorial() {
    this.showLoadingPage();
    this.setTutorialStep(1);
    this.increaseMoney(1500);
    const tutorialLevel = this.data.levels.find(
        (el) => el.name == 'tutorialLevel',
    );
    if (tutorialLevel !== undefined) {
      this.data.loadLevel(tutorialLevel).then(
          () => {
            this.page = Page.game;
            this.getMaxRounds();
            this.addUpcomingEvents();
            this.shop.refillTutorialShop(this.data);
            this.nextTurn();
          },
      );
    }
  }

  /** Start Short Version **/
  startShortVersion() {
    const initialLevel = this.data.levels.find(
        (eel) => eel.name == 'initialLevel',
    );
    if (initialLevel !== undefined) {
      this.selectLevel(initialLevel);
    }
  }

  /** End tutorial **/
  endTutorial() {
    this.setTutorialStep(-1);
    this.upcomingEvents = [];
    this.money = 0;
    this.turn = 0;
    this.data.loadedLevel = undefined;
    /** handle short Version if requested**/
    if (document.location.pathname.toLowerCase() == '/securitycards/short') {
      this.showLoadingPage();
      this.startShortVersion();
    } else {
      this.showLoadingPage();
      this.showLevelSelectionPage();
    }
  }

  /** show levelselection page **/
  showLevelSelectionPage() {
    this.page = Page.levelselection;
  }

  /** Set the state initialized and ready to use **/
  init() {
    this.page = Page.explain;
  }
}
