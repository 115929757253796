import {getJSON} from 'src/utils/Requests/Requests';
import {AvailabilityTag, Tag} from './SecurityEventsApi';

/** ISkill interface representing any kind of skill **/
export interface ISkill {
  label: string,
  name: string,
  description: string,
  effectText: string,
  price: number,
  playPrice: number,
  tags: Tag[],
  avTags: AvailabilityTag[],
  effectValue: number,
  isProbability: boolean,
  isAutoPlay: boolean,
  isAvailability: boolean,
  cardType: string,
  hintText?: string,
  operation?: string,
  effectNames?: string[],
}

export enum operations {
  ADD = 'ADD',
  SUB = 'SUB',
  SET = 'SET'
}

/** API Prefix **/
const PATH_PREFIX = '/SecurityCards/api/skills/';


/** fetches skills from server and parses to skill objects */
export async function getSkills(eventNames: string[]): Promise<ISkill[]> {
  const skills: ISkill[] = [];
  for (let i = 0; i < eventNames.length; i ++) {
    skills.push(await getJSON(PATH_PREFIX + eventNames[i] + '.json'));
  }
  return skills;
}
