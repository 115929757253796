import Event from './Event';
import {Tag, AvailabilityTag}
  from 'src/api/SecurityCardsKRITIS/SecurityEventsApi';
import {ISkill} from 'src/api/SecurityCardsKRITIS/SecuritySkillsApi';
import Effect from '../effects/Effect';


/** implementation of ISkill */
class Skill implements ISkill {
  label: string;
  name: string;
  description: string;
  effectText: string;
  price: number;
  playPrice: number;
  tags: Tag[] = [];
  avTags: AvailabilityTag[] = [];
  effectValue: number;
  isProbability: boolean;
  isAutoPlay: boolean;
  isAvailability: boolean;
  cardType: string;
  hintText: string|undefined;
  operation: string|undefined;
  effects: Effect[]|undefined;
  effectNames: string[];

  /** default constructor */
  constructor(template: ISkill) {
    this.label = template.label;
    this.name = template.name;
    this.description = template.description;
    this.effectText = template.effectText;
    this.tags = template.tags;
    this.avTags = template.avTags;
    this.price = template.price;
    this.effectValue = template.effectValue;
    this.isProbability = template.isProbability;
    this.isAutoPlay = template.isAutoPlay;
    this.isAvailability = template.isAvailability;
    this.playPrice = template.playPrice;
    this.cardType = template.cardType;
    this.hintText = template.hintText;
    this.operation = template.operation;
    this.effectNames = (template.effectNames != undefined) ?
      template.effectNames: [];
  }

  /** checks if the skill card is applyable to the
   * passed event by comparing the tags.
   **/
  checkFor(event: Event): boolean {
    // Preventive skills are allways applyable
    if (this.isProbability) {
      return true;
    }

    for (let i = 0; i < this.tags.length; i++) {
      if (event.tags.indexOf(this.tags[i]) >= 0) {
        return true;
      }
    }
    return false;
  }
}
export default Skill;
