import {makeObservable, observable, action, computed} from 'mobx';
import {Tag, AvailabilityTag} from
  'src/api/SecurityCardsKRITIS/SecurityEventsApi';
import Skill from '../cards/Skill';
import CardEffect, {isCardEffect} from './CardEffect';
import Effect from './Effect';
import IncomeEffect, {isIncomeEffect} from './IncomeEffect';
import ProbabilityEffect, {isProbabilityEffect} from './ProbabilityEffect';
import BlockEffect, {isBlockEffect} from './BlockEffect';
import AvailabilityEffect, {isAvailabilityEffect} from './AvailabilityEffect';
import {toast} from 'react-toastify';

export const AWARENESS_CAP = 0.7;
export const AVAILABILITY_CAP = 4;

/** container class for active effects */
export default class Effects {
  effects: Effect[] = [];
  /** default construcor */
  constructor() {
    makeObservable(this, {
      awarenessLevels: computed,
      availabilityLevels: computed,
      tick: action,
      effectSkills: computed,
      addEffect: action,
      effects: observable,
      incomeValues: computed,
    });
  }

  /** adds effect to effects */
  addEffect(effect:Effect) {
    this.effects.push(effect);
  }

  /**
   * ticks down all active evects
   */
  tick() {
    let warnText =
      'Mindestens ein aktiver Effekt ist ausgelaufen, betroffen ist:';
    let warnMoney = ' das Einkommen';
    let warnCard = ' Karten verfügbarkeit';
    let warnEffect = ' ein Schutzeffekt';
    let warnAvailability = ' die Verfügbarkeit';
    this.effects.forEach((effect) => {
      if (effect.duration > 0) {
        effect.duration--;
      }
      if (effect.duration == 0) {
        if (isIncomeEffect(effect)) {
          warnText += warnMoney;
          warnMoney = '';
        } else if (isCardEffect(effect)) {
          warnText += warnCard;
          warnCard = '';
        } else if (isProbabilityEffect(effect)) {
          warnText += warnEffect;
          warnEffect = '';
        } else if (isAvailabilityEffect(effect)) {
          warnText += warnAvailability;
          warnAvailability = '';
        }
      }
    });
    this.effects = this.effects.filter((effect)=>effect.duration !==0);
    if (warnMoney == '' ||
        warnCard == ''||
        warnEffect == '' ||
        warnAvailability == '') {
      toast.warn(warnText);
    }
  }

  /* updates availability values */
  get availabilityLevels(): Record<AvailabilityTag, number> {
    const values: Record<AvailabilityTag, number> = {
      'Power': 1,
      'Internet': 1,
      'IT-Serviceprovider': 1,
      'Server': 1,
      'Cloud': 1,
      'Staff': 1,
    };
    for (const effect of this.effects) {
      const {tag, availability} = (effect as AvailabilityEffect);
      if (availability) values[tag] += availability;
    }
    const max = AVAILABILITY_CAP;
    const min = 0;
    for (const i of Object.keys(values)) {
      const tag:AvailabilityTag = i as AvailabilityTag;
      values[tag] = values[tag] > max? max:values[tag];
      values[tag] = values[tag] < min? min:values[tag];
    }
    return values;
  }

  /** updates awareness levels */
  get awarenessLevels(): Record<Tag, number> {
    const values: Record<Tag, number> = {
      'Data': 0,
      'Network': 0,
      'Spam': 0,
      'UserFault': 0,
      'Test': 0,
      'DOS': 0,
      'Cloud': 0,
      'Physical': 0,
      'Infrastructure': 0,
    };
    for (const effect of this.effects) {
      const {tag, probability} = (effect as ProbabilityEffect);
      if (probability) values[tag] += probability;
    }
    // keep awareness levels in boundries
    for (const i of Object.keys(values)) {
      // convert i to EffectTag to prevent type error
      const tag:Tag = i as Tag;
      values[tag] = values[tag] > 100? 100:values[tag];
      values[tag] = values[tag] < 0? 0:values[tag];
    }
    return values;
  }

  /** Return blocking Reasons for skill
   * Get the block reason of the current card and compare if its blockable.
   * If blockable, add the block reason to return value.
  **/
  getBlockReasons(card: Skill): string[] {
    const reasons: string[] = [];
    const effects: Effect[] = this.effects.filter(
        (effect)=>isBlockEffect(effect));
    for (const i in effects) {
      if (i) {
        const effect = effects[i] as BlockEffect;
        if (this.blocksCard(card, effect)) {
          reasons.push(effect.blockReason);
        }
      }
    }
    return reasons;
  }

  /** Check if the card is Blocked
   * Help function to check if certain effect blocks a specific card.
  **/
  private blocksCard(card: Skill, effect: BlockEffect):boolean {
    if (effect.blockAllReactive && !card.isProbability) {
      return true;
    }
    if (effect.blockAllPreventive && card.isProbability) {
      return true;
    }
    if (effect.blockByName.includes(card.name)) {
      return true;
    }
    for (const tag in card.tags.values()) {
      if (effect.blockByTags.includes(tag)) {
        return true;
      }
    }
    return false;
  }

  /** check if card is blocked,
   * Iterate through current effects and check if it blocks the specific card.
  **/
  isCardBlocked(card: Skill): boolean {
    const effects: Effect[] = this.effects.filter(
        (effect)=>isBlockEffect(effect));
    for (const i in effects) {
      if (i) {
        if (this.blocksCard(card, effects[i] as BlockEffect)) {
          return true;
        }
      }
    }
    return false;
  }

  /** get array of skills from CardEffects */
  get effectSkills(): Skill[] {
    return this.effects.filter((effect)=>isCardEffect(effect)).
        map((effect)=>(effect as CardEffect).skill);
  }

  /** get array of income amounts from incomeEffects*/
  get incomeValues(): number[] {
    return this.effects.filter((effect)=>isIncomeEffect(effect)).
        map((effect)=>(effect as IncomeEffect).amount);
  }

  get availabilityDays(): Record<AvailabilityTag, number> {
    const values: Record<AvailabilityTag, number> = {
      'Power': 0,
      'Internet': 0,
      'IT-Serviceprovider': 0,
      'Server': 0,
      'Cloud': 0,
      'Staff': 0,
    };

    for (const effect of this.effects) {
      const {tag, failureDays} = (effect as AvailabilityEffect);
      if (failureDays) values[tag] += failureDays;
    }
    const max = 5;
    const min = 0;
    for (const i of Object.keys(values)) {
      const tag:AvailabilityTag = i as AvailabilityTag;
      values[tag] = values[tag] > max? max:values[tag];
      values[tag] = values[tag] < min? min:values[tag];
    }
    return values;
  }

  /** get array of income effects **/
  get incomeEffects(): IncomeEffect[] {
    return this.effects.filter((effect)=>isIncomeEffect(effect)).
        map((effect)=>(effect as IncomeEffect));
  }

  /** get array of availability effects **/
  get availabilityEffects(): AvailabilityEffect[] {
    return this.effects.filter((effect)=>isAvailabilityEffect(effect)).
        map((effect)=>(effect as AvailabilityEffect));
  }

  /** removes a cardeffect by its corresponding skill object */
  removeCardEffect(skill: Skill) {
    for (let i=0; i < this.effects.length; i++) {
      if ((this.effects[i] as CardEffect).skill == skill) {
        this.effects.splice(i, 1);
      }
    }
  }
}
